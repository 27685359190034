module.exports = {
    config: {
        merchantId: 3,
        merchantShopName: "Fleurs à Croquer",
        merchantDomain: "https://www.fleurs-a-croquer.com/",
        defaultMetaImage: "assets/images/FAC/meta-shop-picture.png",
        enabledLanguagues: ['fr'],
        defaultLanguage: 'fr',
        enabledCurrencies: ["EUR"],
        defaultCurrency: "EUR",
        enabledDeliveryCountry: ['fr', 'ch', 'de', 'be', 'lu', 'nl', 'it', 'es', 'at', 'pt', 'ie', 'dk', 'ee', 'hu',
            'lv', 'lt', 'pl', 'cz', 'sk', 'si', 'se', 'ch', 'bg', 'cy', 'hr', 'fi', 'gr', 'is', 'mt', 'no', 'ro'],
        nbProductsByPage: 12,
        shopEmail: "contact@fleurs-a-croquer.com",
        shopPhone: "04 93 59 28 91",
        stripeCountryAccount: 'FR',
        shopAddress: "864 Chemin Saint Martin",
        shopPostalCity: "06140 Tourrettes-sur-Loup",
        shopCountry: "France",
        shopCountryCode: "FR",
        shopSlogan: {
            "fr": "Fleurs cristallisées en ligne : producteur de fleurs - Violettes cristallisées"
        },
        shopMapsUrl: "https://goo.gl/maps/Xa2py72pjH4BMESt6",
        socialMedias: {
            facebook: "https://www.facebook.com/Earl-la-violette-161033253921561",
        },
        footerLink: [
            {
                "name": "Livraison",
                "url": "/delivery"
            },
            {
                "name": "FOOTER_LNOTICE",
                "url": "/legal-notice"
            },
            {
                "name": "Politique de confidentialité",
                "url": "/privacy-policy"
            },
            {
                "name": "FOOTER_TERMS",
                "url": "/terms-of-sales"
            },
        ],
        menu: [
            {
                "name": "NAV_HOME",
                "path": "home",
                "submenu": []
            },
            {
                "name": "NAV_CATALOG",
                "path": "catalog",
                "submenu": []
            },
            {
                "name": "Photos",
                "path": "gallery",
                "submenu": []
            },
            {
                "name": "A propos",
                "path": "about",
                "submenu": []
            },
            {
                "name": "Contact",
                "path": "contact",
                "submenu": []
            }
        ],
        mondialRelayCode: "BDTEST  ",
        mondialRelayColLivMod: "24R",
        stripeKey: "pk_live_51IDX9sFONvuSNpV2o5KatJP8qQncNXdbw5DfzLuVwEoRk6dyyxdC0U3WasFIWMO25RidY1DF6TO9HncLY1YMyDrl00vpUhDuJU",
        stripeAccount: "acct_1Jec8r2apM2IxCDH",
        quoteDownloadEnabled: true,
        reviewEnabled: true,
        defaultCatalogSorting: 'RELEVANCE',
        enabledB2B: true,
        redirect: [
            {
                source: '/savon-la-violette-xml-355_378-1101.html',
                destination: '/product/savon-a-la-violette-100-gr',
                permanent: true,
            }, {
                source: '/savon-la-rose-xml-355_378-1108.html',
                destination: '/product/savon-de-rose-100-gr',
                permanent: true,
            }, {
                source: '/fleurs-de-violettes-100g-xml-355_253_389-1088.html',
                destination: '/product/fleurs-de-violettes-100g',
                permanent: true,
            }, {
                source: '/fleurs-de-violettes-1kg-xml-355_253_381-1084.html',
                destination: '/product/fleurs-de-violettes-1kg',
                permanent: true,
            }, {
                source: '/feuilles-de-menthe-100g-xml-355_253_389-1092.html',
                destination: '/product/feuilles-de-menthe-100g',
                permanent: true,
            }, {
                source: '/feuilles-de-verveine-1kg-xml-355_253_381-1086.html',
                destination: '/product/feuilles-de-verveine-100g',
                permanent: true,
            }, {
                source: '/melange-de-fleurs-100g-xml-355_253_389-1094.html',
                destination: '/product/melange-de-fleurs100g',
                permanent: true,
            }, {
                source: '/melange-de-fleurs-violette-rose-100-gr-xml-355_253_389-1123.html',
                destination: '/product/melange-de-fleurs-violetterose-100g',
                permanent: true,
            }, {
                source: '/petales-de-roses-100g-xml-355_253_389-1089.html',
                destination: '/product/petales-de-roses-100g',
                permanent: true,
            }, {
                source: '/petales-de-roses-1kg-xml-355_253_381-1085.html',
                destination: '/product/petales-de-rose-1-kg',
                permanent: true,
            }, {
                source: '/feuilles-de-menthe-1kg-xml-355_253_381-1087.html',
                destination: '/product/feuilles-de-menthe-1-kg',
                permanent: true,
            }, {
                source: '/feuilles-de-verveine-1kg-xml-355_253_381-1086.html',
                destination: '/product/feuilles-de-verveine-1-kg',
                permanent: true,
            }, {
                source: '/sirop-rose-10cl-xml-355_363_383-1100.html',
                destination: '/product/sirop-rose-10-cl',
                permanent: true,
            }, {
                source: '/sirop-rose-25cl-xml-355_363_384-1098.html',
                destination: '/product/sirop-rose-25-cl',
                permanent: true,
            }, {
                source: '/sirop-violette-25cl-xml-355_363_384-1097.html',
                destination: '/product/sirop-violette-25-cl',
                permanent: true,
            }, {
                source: '/petales-de-rose-seches-30g-xml-355_393_395-1118.html',
                destination: '/product/petales-rose-seches-30-g',
                permanent: true,
            }, {
                source: '/petales-de-rose-seches-10g-xml-355_393_395-1117.html',
                destination: '/product/petales--rose-seches-10-gr',
                permanent: true,
            }, {
                source: '/savon-la-verveine-xml-355_378-1110.html',
                destination: '/product/savon-a-la-verveine-100-gr',
                permanent: true,
            }, {
                source: '/fleurs-de-violette-sechees-10g-xml-355_393_394-1116.html',
                destination: '/product/fleurs-de-violette-sechees-10-g',
                permanent: true,
            }, {
                source: '/fleurs-de-violette-sechees-30g-xml-355_393_394-1115.html',
                destination: '/product/fleurs-violette-sechees-30-g',
                permanent: true,
            }, {
                source: '/sirop-violette-10cl-xml-355_363_383-1099.html',
                destination: '/product/sirop-violette-10-cl',
                permanent: true,
            }, {
                source: '/boite-de-10-bouquets-individuels-xml-355_380_397-1109.html',
                destination: '/product/boite-de-10-bouquets-de-violette-individuels',
                permanent: true,
            }, {
                source: '/gros-bouquet-compose-de-10-petits-bouquets-xml-355_380_397-1119.html',
                destination: '/product/gros-bouquet-compose-de-10-petits-bouquets',
                permanent: true,
            }, {
                source: '/fleurs-de-violettes-equeutees-100g-xml-355_380_398-1120.html',
                destination: '/product/fleurs-de-violette-equeutees-100-g',
                permanent: true,
            },
        ]
    }
}
